import Lenis from "lenis";
import { DeviceType } from "../../../utils/constants";

export const detectDeviceType = () => {
  const screenWidth = window.innerWidth;
  if (screenWidth < 600) {
    return DeviceType.mobile;
  } else if (screenWidth < 992) {
    return DeviceType.tablet;
  } else {
    return DeviceType.pc;
  }
};

export const capitalizeWord = (word) => {
  if (!word) return "";
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
};

export const initiateSmoothScroll = () => {
  const lenis = new Lenis({
    wrapper: document.getElementById("bodyWrap"),
  });

  function raf(time) {
    lenis.raf(time);
    requestAnimationFrame(raf);
  }

  requestAnimationFrame(raf);
};

export const constructVariants = (type) =>
  ({
    left: {
      initial: { opacity: 0, x: -30 },
      in: {
        opacity: 1,
        x: 0,
        transition: {
          duration: 0.5,
          type: "spring",
          stiffness: 50,
          damping: 20,
        },
      },
      out: {
        opacity: 0,
        x: -30,
        transition: {
          duration: 0.3,
        },
      },
    },
    right: {
      initial: { opacity: 0, x: 30 },
      in: {
        opacity: 1,
        x: 0,
        transition: {
          duration: 0.5,
          type: "spring",
          stiffness: 50,
          damping: 20,
        },
      },
      out: {
        opacity: 0,
        x: 30,
        transition: {
          duration: 0.3,
        },
      },
    },
    top: {
      initial: {
        opacity: 0,
        y: -50,
      },
      in: {
        opacity: 1,
        y: 0,
        transition: {
          type: "spring",
          stiffness: 50,
          damping: 20,
        },
      },
      out: {
        opacity: 0,
        y: -50,
        transition: {
          duration: 0.3,
        },
      },
    },
    bottom: {
      initial: {
        opacity: 0,
        y: 50,
      },
      in: {
        opacity: 1,
        y: 0,
        transition: {
          type: "spring",
          stiffness: 50,
          damping: 20,
        },
      },
      out: {
        opacity: 0,
        y: 50,
        transition: {
          duration: 0.3,
        },
      },
    },
    fade: {
      initial: {
        opacity: 0,
      },
      in: {
        opacity: 1,
        transition: {
          type: "spring",
          stiffness: 50,
          damping: 20,
        },
      },
      out: {
        opacity: 0,
        transition: {
          duration: 0.3,
        },
      },
    },
  }[type]);

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
export function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
}

export function getIsDesktopBrowser() {
  return window.orientation === undefined;
}
