import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Col } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { _STYLES } from "./utils/customStyles";
import { createUseStyles } from "react-jss";

const { mb2 } = _STYLES;
const useStyles = createUseStyles({
  mb2,
});

const GoBack = ({ to, text }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Col className={classes.mb2}>
      <Button
        onClick={() => navigate(to || "/")}
        icon={<ArrowLeftOutlined />}
        type="default"
      >
        {text || "Go Back"}
      </Button>
    </Col>
  );
};

export default GoBack;
