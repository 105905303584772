import React, { useCallback } from "react";
import { Button, Dropdown } from "antd";
import { Col, Row } from "antd";
import { CustomContainer } from "./components/container";
import { createUseStyles } from "react-jss";
import { _STYLES } from "./utils/customStyles";
import { useNavigate } from "react-router";
import { useMetaContext } from "../core/contexts/MetaContextProvider";
import { DeviceType } from "../../utils/constants";

import { MenuOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";

const {
  navBarWrap,
  navBarHeader,
  navLink,
  customFont,
  logo,
  navBarShadow,
  navDropdown,
  w100,
  navBtn,
} = _STYLES;
const useStyles = createUseStyles({
  navBarWrap,
  navBarHeader,
  navLink,
  customFont,
  logo,
  navBarShadow,
  navDropdown,
  w100,
  navBtn,
});

const NavBar = ({ shadowed = false }) => {
  const location = useLocation();

  const { deviceType } = useMetaContext();

  const isResponsive = deviceType !== DeviceType.pc;

  const classes = useStyles();

  const navigate = useNavigate();

  const _NAVITEMS = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "Blogs",
      link: "/blogs",
    },
    {
      name: "Support",
      link: "/support",
    },
    {
      name: "Contact",
      link: "/contact",
    },
    {
      name: "Privacy Policy",
      link: "/privacy-policy",
    },
  ];

  const handleScrollToDownloadSection = () => {
    navigate("/#download");
  };

  const mobileMenu = useCallback(() => {
    const items = _NAVITEMS.map((item, index) => ({
      key: `navigationItem-${index}`,
      label: (
        <a
          onClick={() => navigate(item.link, { preventScrollReset: true })}
          className={classes.navLink}
        >
          {item.name}
        </a>
      ),
    }));
    items.push({
      key: "navigationItem-download",
      label: (
        <Button
          className={classes.customFont}
          block
          type="primary"
          shape="round"
          onClick={handleScrollToDownloadSection}
        >
          Download Now
        </Button>
      ),
    });

    return (
      <Dropdown
        menu={{
          items,
        }}
        placement="topRight"
        arrow
        rootClassName={classes.navDropdown}
      >
        <Button className={classes.navBtn}>
          <MenuOutlined />
        </Button>
      </Dropdown>
    );
  }, [
    _NAVITEMS,
    classes.customFont,
    classes.navBtn,
    classes.navDropdown,
    classes.navLink,
    handleScrollToDownloadSection,
    navigate,
  ]);

  return (
    <Col
      className={`${classes.navBarWrap} ${
        shadowed ? classes.navBarShadow : ""
      }`}
    >
      <CustomContainer className={classes.w100}>
        <Row justify="space-between" align="middle">
          <a onClick={() => navigate("/")}>
            <Row gutter={[16, 0]} align="middle">
              <Col>
                <img className={classes.logo} src="/images/logo.png" alt="" />
              </Col>
              <Col>
                <h4 className={classes.navBarHeader + " " + classes.customFont}>
                  Unstray Me
                </h4>
              </Col>
            </Row>
          </a>

          {isResponsive ? (
            mobileMenu()
          ) : (
            <Row align={"middle"} gutter={[24, 0]}>
              {_NAVITEMS.map((item, index) => (
                <Col key={"nav-" + index}>
                  <a
                    onClick={() => navigate(item.link)}
                    className={classes.navLink}
                  >
                    {item.name}
                  </a>
                </Col>
              ))}
              <Col>
                <Button
                  className={classes.customFont}
                  block
                  type="primary"
                  shape="round"
                  onClick={handleScrollToDownloadSection}
                >
                  Download Now
                </Button>
              </Col>
            </Row>
          )}
        </Row>
      </CustomContainer>
    </Col>
  );
};

export default NavBar;
