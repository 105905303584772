import React, { useCallback, useState } from "react";
import { createUseStyles } from "react-jss";
import { _STYLES } from "./customStyles";

import { Image as AntdImage } from "antd";

const {
  bgImgPlaceholder,
  imgPlaceholder,
  imgWrap,
  imgContainer,
  imgInnerWrap,
} = _STYLES;
const useStyles = createUseStyles({
  bgImgPlaceholder,
  imgPlaceholder,
  imgWrap,
  imgContainer,
  imgInnerWrap,
});

const Image = ({
  src,
  isBgPlaceholder,
  preview = false,
  className = "",
  ...imageProps
}) => {
  const [isError, setIsError] = useState(false);

  const classes = useStyles();

  const handleImageError = useCallback(() => setIsError(true), []);

  return (
    <div className={classes.imgContainer + " " + (className ?? "")}>
      {isError ? (
        <div className={classes.imgPlaceholder} />
      ) : (
        <div className={classes.imgWrap}>
          <AntdImage
            {...imageProps}
            onError={handleImageError}
            alt="blog-img"
            src={src || ""}
            wrapperClassName={classes.imgInnerWrap}
            preview={preview}
          />

          {isBgPlaceholder && (
            <div className={classes.bgImgPlaceholder}>
              <div></div>
              <img alt="blog-placeholder" src={src} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Image;
