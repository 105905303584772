import { Col, Modal, Row, Space } from "antd";
import React, { useCallback } from "react";
import { DeviceType } from "../../../utils/constants";
import { useMetaContext } from "../../core/contexts/MetaContextProvider";
import { createUseStyles } from "react-jss";
import { _STYLES } from "../../common/utils/customStyles";
import { motion } from "framer-motion";
import {
  constructVariants,
  getIsDesktopBrowser,
  getMobileOperatingSystem,
} from "../../common/utils/common";
import DownloadAppBadge from "../../home/download_badge";
import { CustomContainer } from "../../common/components/container";

const {
  stripLeft,
  downloadBlock,
  textCenter,
  customFont,
  textMd1,
  dirColumn,
  paraLine,
  mainHeader,
  catHomeImg,
  textLeft,
  w100,
} = _STYLES;
const useStyles = createUseStyles({
  stripLeft,
  downloadBlock,
  textCenter,
  customFont,
  textMd1,
  dirColumn,
  paraLine,
  mainHeader,
  catHomeImg,
  textLeft,
  w100,
});

const DownloadPromptModal = ({ isModelOpen, toggleModal }) => {
  const guestOS = getMobileOperatingSystem();
  const isDesktop = getIsDesktopBrowser();
  const classes = useStyles();

  const { deviceType } = useMetaContext();

  const isMobile = deviceType === DeviceType.mobile;
  const isResponsive = deviceType !== DeviceType.pc;

  const handleCancel = useCallback(() => {
    toggleModal();
  }, [toggleModal]);

  return (
    <Modal
      open={isModelOpen}
      onCancel={handleCancel}
      footer={null}
      width={isMobile ? "100%" : 1080}
    >
      <CustomContainer padTop="sm" padBottom="sm">
        <Row gutter={[0, { xs: 32, sm: 32, md: 0 }]} align={"middle"}>
          <Col className={classes.textCenter} xs={24} sm={24} md={12}>
            <motion.img
              initial="initial"
              animate={"in"}
              exit="out"
              variants={constructVariants("left")}
              style={{ position: "relative" }}
              alt="download-section-img"
              className={classes.catHomeImg}
              src="/images/app.png"
            />
          </Col>
          <Col xs={24} sm={24} md={10} offset={isResponsive ? 0 : 2}>
            <motion.div
              initial="initial"
              animate={"in"}
              exit="out"
              variants={constructVariants("right")}
              style={{ position: "relative" }}
            >
              <Space direction="vertical" size={[0, isResponsive ? 24 : 48]}>
                <h2 className={` ${classes.mainHeader}`}>
                  Do more with the app!
                </h2>
                <p
                  className={
                    classes.textMd1 +
                    " " +
                    classes.paraLine +
                    " " +
                    classes.textLeft
                  }
                >
                  Make your adoption journey easier with the “Unstray Me” app!
                  Stay connected with real-time messaging and notifications.
                  Enjoy the convenience—download our app today!
                </p>
                <Col className={classes.textCenter} span={24}>
                  <Space
                    className={!isResponsive ? classes.w100 : ""}
                    direction="horizontal"
                    size={[48, 0]}
                  >
                    {(isDesktop || guestOS === "iOS") && (
                      <DownloadAppBadge type="ios" />
                    )}
                    {(isDesktop || guestOS === "Android") && (
                      <DownloadAppBadge type="android" />
                    )}
                  </Space>
                </Col>
              </Space>
            </motion.div>
          </Col>
        </Row>
      </CustomContainer>
    </Modal>
  );
};

export default DownloadPromptModal;
