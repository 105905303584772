import React, { useContext, useEffect, useState } from "react";
import { useWindowSize } from "../../common/hooks/useWindowSize";
import { detectDeviceType } from "../../common/utils/common";

const MetaContext = React.createContext({
  deviceType: undefined,
});

export const useMetaContext = () => useContext(MetaContext);

export const MetaContextProvider = ({ children }) => {
  const [deviceType, setDeviceType] = useState(detectDeviceType());

  const [screenWidth] = useWindowSize();

  useEffect(() => {
    const detectedDeviceType = detectDeviceType();

    if (deviceType !== detectedDeviceType) {
      setDeviceType(detectDeviceType());
    }
  }, [deviceType, screenWidth]);

  return (
    <MetaContext.Provider
      value={{
        deviceType,
      }}
    >
      {children}
    </MetaContext.Provider>
  );
};
