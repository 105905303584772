import LocalizedStrings from 'react-localization';
import navbarTexts from './navbar';
import supportTexts from './support';

const strings = new LocalizedStrings({
    en: {
        // navbar
        ...navbarTexts.en,

        // support
        ...supportTexts.en,
    }, si: {
        // navbar
        ...navbarTexts.si,

        // support
        ...supportTexts.si,
    }
});

export default strings;