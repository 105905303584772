import Link from "antd/es/typography/Link";
import { createUseStyles } from "react-jss";
import { _STYLES } from "../common/utils/customStyles";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebase-config";

const { downloadBlock, downloadBlockImgHome } = _STYLES;
const useStyles = createUseStyles({
  downloadBlock,
  downloadBlockImgHome,
});

const DownloadAppBadge = (props) => {
  const classes = useStyles();

  const fileName = props.type === "ios" ? "app-store" : "google-play-badge";
  const storeURL =
    props.type === "ios"
      ? "https://apps.apple.com/lk/app/unstray-me/id1604332859"
      : "https://play.google.com/store/apps/details?id=com.sajadjaward.unstrayme";

  return (
    <div className={classes.downloadBlock}>
      <Link
        href={storeURL}
        onClick={(evt) => {
          logEvent(analytics, `${props.type}_download`);
        }}
      >
        <img
          className={classes.downloadBlockImgHome}
          alt="Download Now"
          src={`/images/store_badges/${fileName}.png`}
        />
      </Link>
    </div>
  );
};

export default DownloadAppBadge;
