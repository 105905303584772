import React from "react";
import { Divider, Space } from "antd";
import { Col, Row } from "antd";
import { CustomContainer } from "./components/container";
import { createUseStyles } from "react-jss";
import { _STYLES } from "./utils/customStyles";
import { useNavigate } from "react-router";
import { useMetaContext } from "../core/contexts/MetaContextProvider";
import { DeviceType } from "../../utils/constants";

const {
  navBarWrap,
  navBarHeader,
  navLink,
  customFont,
  logo,
  miniHeader,
  footerWrap,
  subHeader,
  footerLink,
  mb1,
  footerHeader,
  socialWrap,
  footerBg,
  textMd1,
  colorLight,
  paraLine,
  textCenter,
  coloredDivider,
  w100,
} = _STYLES;
const useStyles = createUseStyles({
  navBarWrap,
  navBarHeader,
  navLink,
  customFont,
  logo,
  miniHeader,
  footerWrap,
  subHeader,
  footerLink,
  mb1,
  footerHeader,
  socialWrap,
  footerBg,
  textMd1,
  colorLight,
  paraLine,
  textCenter,
  coloredDivider,
  w100,
});

const _FOOTERITEMS = [
  {
    header: "Content",
    items: [
      {
        name: "Home",
        link: "/",
      },
      {
        name: "Blog",
        link: "/blog",
      },
    ],
  },
  {
    header: "Reach us",
    items: [
      {
        name: "About",
        link: "/about",
      },
      {
        name: "Contact",
        link: "/contact",
      },
    ],
  },
  {
    header: "Legal",
    items: [
      {
        name: "Support",
        link: "/support",
      },
      {
        name: "Privacy Policy",
        link: "/privacy-policy",
      },
    ],
  },
];

const _SOCIAL = [
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-3 7h-1.924c-.615 0-1.076.252-1.076.889v1.111h3l-.238 3h-2.762v8h-3v-8h-2v-3h2v-1.923c0-2.022 1.064-3.077 3.461-3.077h2.539v3z" />
      </svg>
    ),
    link: "https://facebook.com/unstray.me",
    name: "facebook",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path d="M15.233 5.488c-.843-.038-1.097-.046-3.233-.046s-2.389.008-3.232.046c-2.17.099-3.181 1.127-3.279 3.279-.039.844-.048 1.097-.048 3.233s.009 2.389.047 3.233c.099 2.148 1.106 3.18 3.279 3.279.843.038 1.097.047 3.233.047 2.137 0 2.39-.008 3.233-.046 2.17-.099 3.18-1.129 3.279-3.279.038-.844.046-1.097.046-3.233s-.008-2.389-.046-3.232c-.099-2.153-1.111-3.182-3.279-3.281zm-3.233 10.62c-2.269 0-4.108-1.839-4.108-4.108 0-2.269 1.84-4.108 4.108-4.108s4.108 1.839 4.108 4.108c0 2.269-1.839 4.108-4.108 4.108zm4.271-7.418c-.53 0-.96-.43-.96-.96s.43-.96.96-.96.96.43.96.96-.43.96-.96.96zm-1.604 3.31c0 1.473-1.194 2.667-2.667 2.667s-2.667-1.194-2.667-2.667c0-1.473 1.194-2.667 2.667-2.667s2.667 1.194 2.667 2.667zm4.333-12h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm.952 15.298c-.132 2.909-1.751 4.521-4.653 4.654-.854.039-1.126.048-3.299.048s-2.444-.009-3.298-.048c-2.908-.133-4.52-1.748-4.654-4.654-.039-.853-.048-1.125-.048-3.298 0-2.172.009-2.445.048-3.298.134-2.908 1.748-4.521 4.654-4.653.854-.04 1.125-.049 3.298-.049s2.445.009 3.299.048c2.908.133 4.523 1.751 4.653 4.653.039.854.048 1.127.048 3.299 0 2.173-.009 2.445-.048 3.298z" />
      </svg>
    ),
    link: "https://instagram.com/unstray.me",
    name: "instagram",
  },
  /*{
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-.139 9.237c.209 4.617-3.234 9.765-9.33 9.765-1.854 0-3.579-.543-5.032-1.475 1.742.205 3.48-.278 4.86-1.359-1.437-.027-2.649-.976-3.066-2.28.515.098 1.021.069 1.482-.056-1.579-.317-2.668-1.739-2.633-3.26.442.246.949.394 1.486.411-1.461-.977-1.875-2.907-1.016-4.383 1.619 1.986 4.038 3.293 6.766 3.43-.479-2.053 1.08-4.03 3.199-4.03.943 0 1.797.398 2.395 1.037.748-.147 1.451-.42 2.086-.796-.246.767-.766 1.41-1.443 1.816.664-.08 1.297-.256 1.885-.517-.439.656-.996 1.234-1.639 1.697z" />
        </svg>
      ),
      link: "",
      name: "twitter",
    },
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
        </svg>
      ),
      link: "",
      name: "linkedin",
    },*/
];

const Footer = () => {
  const { deviceType } = useMetaContext();

  const isResponsive = deviceType !== DeviceType.pc;

  const classes = useStyles();

  const navigate = useNavigate();

  return (
    <Col className={classes.footerWrap}>
      <CustomContainer padBottom="sm" padTop="lg">
        <Space
          className={classes.w100}
          direction="vertical"
          size={[0, isResponsive ? 24 : 48]}
        >
          <Row
            className={isResponsive ? classes.textCenter : ""}
            justify={isResponsive ? "center" : "start"}
            gutter={[
              { xs: 16, sm: 16, md: 64 },
              { xs: 32, sm: 32, md: 0 },
            ]}
            align="middle"
          >
            <Col xs={24} sm={24} md={6}>
              <Space direction="vertical" size={"middle"}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a onClick={() => navigate("/")}>
                  <Row gutter={[16, 0]} align="middle">
                    <Col>
                      <img
                        className={classes.logo}
                        src="/images/logo.png"
                        alt=""
                      />
                    </Col>
                    <Col>
                      <h4
                        className={
                          classes.footerHeader + " " + classes.customFont
                        }
                      >
                        Unstray Me
                      </h4>
                    </Col>
                  </Row>
                </a>
              </Space>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Row justify={"center"} gutter={[{ xs: 16, sm: 16, md: 24 }, 0]}>
                {_FOOTERITEMS.map((footerItem, index) => (
                  <Col key={"footer1-" + index} span={24 / _FOOTERITEMS.length}>
                    <h5
                      className={
                        classes.miniHeader +
                        " " +
                        classes.customFont +
                        " " +
                        classes.mb1
                      }
                    >
                      {footerItem.header}
                    </h5>
                    <Space direction="vertical" size={"small"}>
                      {footerItem.items.map((navItems, index) => (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <a
                          key={"footer2-" + index}
                          onClick={() => navigate(navItems.link)}
                          className={classes.footerLink}
                        >
                          {navItems.name}
                        </a>
                      ))}
                    </Space>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col xs={24} sm={24} md={6}>
              <Row
                justify={isResponsive ? "center" : "end"}
                className={classes.socialWrap}
                gutter={[16, 16]}
              >
                {_SOCIAL.map((item, index) => (
                  <Col key={"footer3-" + index}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a onClick={() => window.location.replace(item.link)}>
                      {item.icon}
                    </a>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
          <Divider className={classes.coloredDivider} />
          <p
            className={
              classes.textMd1 +
              " " +
              classes.paraLine +
              " " +
              classes.textCenter
            }
          >
            © 2023 Unstray Me. All rights reserved.
          </p>
        </Space>
      </CustomContainer>
    </Col>
  );
};

export default Footer;
