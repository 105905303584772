import { Col, ConfigProvider } from "antd";
import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { MetaContextProvider } from "../core/contexts/MetaContextProvider";
import { NotificationContextProvider } from "../core/contexts/NotificationContextProvider";
import globalStyle from "../globalStyles";
import { _THEME } from "../theme";
import Footer from "./footer";
import Navbar from "./navBar";
import { _STYLES } from "./utils/customStyles";

const { bodyWrap, bodyInnerWrap } = _STYLES;
const useStyles = createUseStyles({
  bodyWrap,
  bodyInnerWrap,
});

const Layout = (props) => {
  const [navBarShadowed, setNavBarShadowed] = useState(false);

  const classes = useStyles();
  globalStyle();

  const handleScroll = (el) => {
    setNavBarShadowed(el.target.scrollTop > 82);
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: _THEME.color.primary.main,
        },
        components: {
          Typography: {
            colorPrimary: _THEME.color.text.main,
          },
        },
      }}
    >
      <MetaContextProvider>
        <NotificationContextProvider>
          <Navbar shadowed={navBarShadowed} />
          <Col
            id="bodyWrap"
            onScroll={handleScroll}
            className={classes.bodyWrap}
          >
            <Col className={classes.bodyInnerWrap}>{props.children}</Col>
            <Footer />
          </Col>
        </NotificationContextProvider>
      </MetaContextProvider>
    </ConfigProvider>
  );
};

export default Layout;
