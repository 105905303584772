import axios from "axios";

const HOST_NAME = process.env.REACT_APP_API_HOST_NAME;

const apiService = axios.create({ baseURL: `${HOST_NAME}/api` });

export const getBrowsePosts = async (params) => {
  try {
    const response = await apiService.get(
      `posts/all${params ? "?" + params : ""}`
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const saveContactInfo = async (firstName, lastName, email, inquiry) => {
  try {
    await apiService.post(
      "/support/save",
      {
        FirstName: firstName,
        LastName: lastName,
        Email: email,
        Inquiry: inquiry,
      },
      { headers: { "Content-Type": "application/json" } }
    );
  } catch (err) {
    throw err;
  }
};

export const getLocationDataForInput = async (location) => {
  try {
    const response = await apiService.get(`places/get?input=${location}`);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const getPostDetails = async (postId) => {
  try {
    const response = await apiService.get(`posts/${postId}`);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const postInquiry = async (data) => {
  try {
    const response = await apiService.post("posts/inquire", data);
    return response.data;
  } catch (err) {
    throw err;
  }
};
