import { Button, Col, Form, Input, Modal, Row } from "antd";
import React, { useCallback, useEffect } from "react";
import { createUseStyles } from "react-jss";
import { _STYLES } from "../../common/utils/customStyles";

const { customFont, mt1 } = _STYLES;
const useStyles = createUseStyles({
  customFont,
  mt1,
});

const InquiryModal = ({ isModelOpen, toggleModal, onFormSubmit }) => {
  const classes = useStyles();

  const { TextArea } = Input;

  const [postForm] = Form.useForm();

  const handleOk = useCallback(() => {
    toggleModal();
  }, [toggleModal]);

  const handleCancel = useCallback(() => {
    toggleModal();
  }, [toggleModal]);

  const handleSubmit = useCallback(
    (data) => {
      onFormSubmit(data);
      toggleModal();
    },
    [onFormSubmit, toggleModal]
  );

  useEffect(() => {
    if (!isModelOpen) {
      postForm.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModelOpen]);

  return (
    <>
      <Modal
        title="Send an inquiry"
        open={isModelOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Row className={classes.mt1}>
          <Col xs={24}>
            <Form
              name="postInquiry"
              initialValues={{ remember: false }}
              onFinish={handleSubmit}
              autoComplete="off"
              layout="vertical"
              form={postForm}
            >
              <Form.Item
                label="Your Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please enter your name",
                  },
                ]}
              >
                <Input size="large" allowClear />
              </Form.Item>
              <Form.Item
                name="phone"
                label="Phone Number"
                rules={[
                  {
                    required: true,
                    message: "Please enter a valid contact number",
                    pattern: new RegExp(
                      /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{3})/
                    ),
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
              <Form.Item
                label="E-mail Address"
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "The input is not a valid email address",
                  },
                  {
                    required: true,
                    message: "Please enter your email address",
                  },
                ]}
              >
                <Input size="large" allowClear />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: false,
                  },
                ]}
                label="Message"
                name="message"
              >
                <TextArea
                  size="large"
                  placeholder="Enter your message"
                  allowClear
                />
              </Form.Item>

              <Row justify={"end"}>
                <Button
                  className={classes.customFont}
                  shape="round"
                  size="large"
                  type="primary"
                  htmlType="submit"
                >
                  Send
                </Button>
              </Row>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default InquiryModal;
