const supportLocalizedStrings = {
    en: {
        // titles
        'support_title_1': 'What is Unstray Me?',
        'support_title_2': 'How do I download Unstray Me?',
        'support_title_3': 'I need to rehome a stray animal. How do I do it?',
        'support_title_4': 'I lost my pet. Can this app help me?',

        // contents
        'support_1_content_1': '<b>Unstray Me</b> is a mobile app which is dedicated for animal welfare and driven by kind people like you. This app mainly focuses on rehoming stray animals. In addition to that, this app lets you find sponsors for animals and help find your lost pets.',
        'support_1_content_2': '',
        'support_1_content_3': '',
        'support_1_content_4': '',
    }, si: {
        // titles
        'support_title_1': 'Unstray Me යනු කුමක්ද?',
        'support_title_2': 'මම Unstray Me බාගත කරන්නේ කෙසේද?',
        'support_title_3': 'මට වීදි සතෙකුට නිවසක් සොයා දීමට අවශ්‍යයි. මම කොහොමද ඒක කරන්නේ?',
        'support_title_4': 'මට මගේ සුරතලා නැති වුණා. මෙම යෙදුමෙන් මට උදව් ලබා ගත හැකිද?',

        // contents
        'support_1_content_1': '',
    }
};

export default supportLocalizedStrings;