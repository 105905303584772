const navbarLocalizedStrings = {
  en: {
    nav_item_1: "Privacy Policy",
    nav_item_2: "Support",
    nav_item_3: "Contact",
    nav_item_4: "Blogs",
  },
  si: {
    nav_item_1: "රහස්යතා ප්‍රතිපත්තිය",
    nav_item_2: "සහය",
    nav_item_3: "අමතන්න",
    nav_item_4: "බ්ලොග්",
  },
};

export default navbarLocalizedStrings;
