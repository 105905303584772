import { Route, Routes } from "react-router-dom";
import strings from "./localization/localization";
import Layout from "./components/common/layout";
import { Suspense, lazy } from "react";
import PostDetails from "./components/post-details/postDetails";
import Loader from "./components/common/loader";
import ScrollToTop from "./components/common/scrollToTop";

const Home = lazy(() => import("./components/home/home"));
const PrivacyPolicy = lazy(() =>
  import("./components/privacy-policy/privacy-policy")
);
const Support = lazy(() => import("./components/support/support"));
const Contact = lazy(() => import("./components/contact/contact"));
const Blogs = lazy(() => import("./components/blogs/blogs"));
const BlogDetails = lazy(() => import("./components/blogs/blog-details"));
const Browse = lazy(() => import("./components/browse/browsePosts"));
const NotFound = lazy(() => import("./components/not-found"));

function App() {
  const lang = localStorage.getItem("lang");
  if (lang) {
    strings.setLanguage(lang);
  }

  return (
    <Layout>
      <ScrollToTop />
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="support" element={<Support />} />
          <Route path="contact" element={<Contact />} />
          <Route path="blogs/:blogId" element={<BlogDetails />} />
          <Route path="post-details/:postId" element={<PostDetails />} />
          <Route path="blogs" element={<Blogs />} />
          <Route path="browse" element={<Browse />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </Layout>
  );
}

export default App;
