import { Col, Spin } from "antd";
import React from "react";
import { createUseStyles } from "react-jss";
import { CustomContainer } from "./components/container";
import { _STYLES } from "./utils/customStyles";

const { mainLoader } = _STYLES;
const useStyles = createUseStyles({
  mainLoader,
});

const Loader = () => {
  const classes = useStyles();

  return (
    <CustomContainer padTop="lg" padBottom="lg">
      <Col className={classes.mainLoader}>
        <Spin size="large" />
      </Col>
    </CustomContainer>
  );
};

export default Loader;
